import React from 'react';

import {Link} from "gatsby";
import {Quote} from "../helper";
import {showcaseItemPath} from "../../utils/linkResolver";


import PartnersBadge from "../../images/partners-badge.inline.svg";
import PartnersBadgeEn from "../../images/partners-badge.en.inline.svg";

// import KickerLogo from '../../images/logos/kicker-sportmagazin-logo.inline.svg';
// import MatchdayLogo from '../../images/logos/kicker-matchday-logo.inline.svg';
// import RedbullLogo from '../../images/logos/red-bull-logo.inline.svg';
// import UnserclubLogo from '../../images/logos/unserclub-logo.inline.svg';
//import TopfitLogo from '../../images/logos/topfit-logo.inline.svg';
// import FlowspaceLogo from '../../images/logos/growspace-logo.inline.svg';
// import VerivoxLogo from '../../images/logos/verivox-logo.inline.svg';
import LegionaereLogo from '../../images/logos/guggenberger-legionaere-logo.inline.svg';
import AschendorffLogo from '../../images/logos/aschendorff-logo.inline.svg';
import FeinRausLogo from '../../images/logos/fein-raus-logo.inline.svg';
import HandballNetLogo from '../../images/logos/handball-net-logo.inline.svg';
import SSVJahnLogo from '../../images/logos/ssv-jahn-regensburg-logo.inline.svg';
// import MadsackLogo from '../../images/logos/madsack-logo.inline.svg';
// import SpiegelLogo from '../../images/logos/der-spiegel-logo.inline.svg';
// import SueddeutscheLogo from '../../images/logos/sueddeutsche-zeitung-digitale-medien-logo.inline.svg';
// import LoewenLogo from '../../images/logos/loewen-frankfurt-logo.inline.svg';
// import EstetalLogo from '../../images/logos/sg-estetal-logo.inline.svg';
// import NordbayernLogo from '../../images/logos/nordbayern-logo.inline.svg';



const Partners = ({data, lang}) => {

  const t = data.prismic.allHomepages.edges[0].node;

  const partners = [
    // {key: 'partner-kicker', image: <KickerLogo />, alt: 'kicker Sportmagazin Logo', uid: 'kicker'},
    // {key: 'partner-matchday', image: <MatchdayLogo />, alt: 'kicker Matchday Logo', uid: 'matchday'},
    // {key: 'partner-redbull', image: <RedbullLogo />, alt: 'Red Bull Logo', uid: 'red-bull-neymar'},
    // {key: 'partner-topfit', image: <TopfitLogo />, alt: 'Topfit Logo', uid: 'topfit-service-deutschland'},
    // {key: 'partner-flowspace', image: <FlowspaceLogo />, alt: 'Growspace Logo', uid: 'growspace'},
    // {key: 'partner-unserclub', image: <UnserclubLogo />, alt: 'UnserClub Logo', uid: 'unserclub'},
    // {key: 'partner-verivox', image: <VerivoxLogo />, alt: 'Verivox Logo', uid: 'verivox'},
    {key: 'partner-legionaere', image: <LegionaereLogo />, alt: 'Legionaere Logo', uid: 'legionaere-showcase'},
    {key: 'partner-aschendorff_app', image: <AschendorffLogo />, alt: 'Aschendorff Logo', uid: 'aschendorff-app'},
    {key: 'partner-fein_raus', image: <FeinRausLogo />, alt: 'Fein Raus Logo', uid: 'fein-raus'},
    {key: 'partner-dhb_handball', image: <HandballNetLogo />, alt: 'Handball net Logo', uid: 'dhb-handball'},
    {key: 'partner-ssv_jahn', image: <SSVJahnLogo />, alt: 'SSV Jahn Logo', uid: 'ssv-jahn'},
    // {key: 'partner-madsack', image: <MadsackLogo />, alt: 'Madsack Mediengruppe Logo', href: null, nofollow: true},
    // {key: 'partner-spiegel', image: <SpiegelLogo />, alt: 'Der Spiegel Logo', href: null, nofollow: true},
    // {key: 'partner-sueddeutsche', image: <SueddeutscheLogo />, alt: 'Süddeutsche Zeitung Digitale Medien Logo', href: null, nofollow: true},
    // {key: 'partner-loewen', image: <LoewenLogo />, alt: 'Löwen Frankfurt Logo', href: null, nofollow: true},
    // {key: 'partner-estetal', image: <EstetalLogo />, alt: 'SG Estetal Logo', href: null, nofollow: true},
    // {key: 'partner-nordbayern', image: <NordbayernLogo />, alt: 'Nordbayern Logo', href: null, nofollow: true}
  ]


  return (
    <section className="partners">
      <div className="partners-badge">{ lang.startsWith('en') ? <PartnersBadgeEn /> : <PartnersBadge />}</div>
      <div className="partners-tick tick"></div>

      <h1 className="partners-headline" dangerouslySetInnerHTML={{__html: t.partners_headline}}></h1>

      <Quote className="partner-quote" author={ t.partners_quote_author }>
        { t.partners_quote_text }
      </Quote>

      <div className="partner-list">
        {partners.map(partner => <Link key={partner.key} className="partner-item" to={showcaseItemPath({
          lang: lang, 
          uid: partner.uid})}>
          {partner.image}
        </Link>)}
      </div>

    </section>
  )
}



export default Partners