import React, { useEffect, useState } from "react";
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js";

import { Link } from "gatsby";
import { RichText } from 'prismic-reactjs'

import { useIsIE } from "../helper";

import logo from "../../images/made-by-logo.svg";
// import digitalExcellence from "../../images/digital-excellence.svg";
import DigitalExcellence from "../../images/digital-excellence.inline.svg";
import DigitalExcellenceIE from "../../images/digital-excellence-ie.inline.svg";
import Bulb from "../../images/intro-bulb-glow.inline.svg";
import { rootPath } from "../../utils/linkResolver";


const Intro = ({ data, lang, scrollMagic }) => {
  const [ scrollY, setScrollY ] = useState(0);
  const [ scrolled, setScrolled ] = useState();
  const isIE = useIsIE();

  const t = data.prismic.allHomepages.edges[0].node;

  useEffect(_ => {
    const handleScroll = _ => {
      // console.log(window.pageYOffset + "-" + scrollY);
      if (window.pageYOffset < scrollY && window.pageYOffset !== 0 && window.pageYOffset > 99) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
      setScrollY(window.pageYOffset);
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  useEffect(() => {

    if(scrollMagic) {

      new scrollMagic.instance.Scene({
        triggerElement: ".intro", 
        duration: "2%",
        triggerHook: 0
      })
      .setClassToggle('.intro-marker-wrapper', 'fade-in')
      .addTo(scrollMagic.controller)
  
      new scrollMagic.instance.Scene({
        triggerElement: ".intro", 
        duration: "50%",
        triggerHook: 0
      })
      .setPin('.intro-container')
      .addTo(scrollMagic.controller)
  
      // build scene and set duration to window height
      new scrollMagic.instance.Scene({
        triggerElement: ".intro", 
        duration: "70%",
        triggerHook: 0,
        offset: window.innerHeight*0.15  
      })
      // .addIndicators()
      .setClassToggle('.intro-text-wrapper', 'fade-in')
      .addTo(scrollMagic.controller);
    }
  }, [scrollMagic])
  

  return (
    <section className="intro">
      <div className="section-logo">
        <Link className={scrolled ? "made-by-logo scrolled" : "made-by-logo"} to={rootPath({lang: lang})}>
          <img alt="made.by Logo" src={logo} />
        </Link>
      </div>
      <div className="intro-wrapper">

        <div className="intro-container">
      
          <div className="intro-marker-wrapper" id="intro-animation">
            <h1 className="intro-marker">
              { isIE ? <DigitalExcellenceIE id="intro-marker-svg" className="intro-marker-svg intro-marker-svg-ie" />
              : <DigitalExcellence id="intro-marker-svg" className="intro-marker-svg" /> }
              <span className="intro-headline">{t.intro_headline}</span>
            </h1>
          </div>
      
          <div className="intro-text-wrapper">
            <h2 className="intro-text">
              {t.intro_slogan && <RichText render={t.intro_slogan} />}
            </h2>
          </div>

          <Bulb className="intro-bulb" />
          <div className="intro-tick tick"></div>
        </div>
      </div>
      {/* <Bulb className="intro-bulb" />
      <div className="intro-tick tick"></div> */}
    </section>
  )
};

export default Intro;