import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import { RichText } from 'prismic-reactjs'
// import Img from "gatsby-image";
import Img from "gatsby-image/withIEPolyfill";

// import { getCurrentLangKey } from 'ptz-i18n';

// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js";
import { TikLink } from "../../components/helper";
import { showcaseItemPath} from "../../utils/linkResolver";

import Badge from "../../images/showcases/showcases-badge.inline.svg";
// import kickerShowcaseImg1200w from "../../images/showcases/kicker/kicker-ios-app-01_1200w.png";
// import redbullShowcaseImg from "../../images/showcases/redbull/redbull-01.png";
// import redbullShowcaseImg2 from "../../images/showcases/redbull/redbull-02.png";
// import matchdayShowcaseImg from "../../images/showcases/matchday/matchday-01.png";
// import topFitShowcaseImg from "../../images/showcases/topfit/topfit-02.png";
// import flowspaceShowcaseImg from "../../images/showcases/flowspace/flowspace-01.png";
// import verivoxShowcaseImg from "../../images/showcases/verivox/verivox-02.png";
// import heretoplayShowcaseImg from "../../images/showcases/here-to-play/heretoplay-01.png";

// const locale = 'de-de'

// const Showcase = ({scrollMagic, data}) => {
const Showcase = ({location, data, scrollMagic}) => {
  
  // const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  // const langKey = getCurrentLangKey(['de-de'], 'de-de');

  // console.log('langKey', langKey);

  const t = data.prismic.allHomepages.edges[0].node;

  const [showAllShowcases, setShowAllShowcases] = useState(false);

  let kickerShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "kicker"; })
  kickerShowcase = kickerShowcase[0] && kickerShowcase[0].node

  let unserclubShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "unserclub"; })
  unserclubShowcase = unserclubShowcase[0] && unserclubShowcase[0].node

  let redbullShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "red-bull"; })
  redbullShowcase = redbullShowcase[0] && redbullShowcase[0].node

  let matchdayShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "matchday"; })
  matchdayShowcase = matchdayShowcase[0] && matchdayShowcase[0].node

  let sdtvShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "sportdeutschland_tv"; })
  sdtvShowcase = sdtvShowcase[0] && sdtvShowcase[0].node

  let verivoxShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "verivox"; })
  verivoxShowcase = verivoxShowcase[0] && verivoxShowcase[0].node

  //let topfitShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "topfit"; })
  //topfitShowcase = topfitShowcase[0] && topfitShowcase[0].node

  // let betsnapShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key == "betsnap"; })
  // betsnapShowcase = betsnapShowcase && betsnapShowcase[0].node

  let nordbayernShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "nordbayern"; })
  nordbayernShowcase = nordbayernShowcase[0] && nordbayernShowcase[0].node
  
  let legionaereShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "legionaere"; })
  legionaereShowcase = legionaereShowcase[0] && legionaereShowcase[0].node

  let fein_rausShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "fein_raus"; })
  fein_rausShowcase = fein_rausShowcase[0] && fein_rausShowcase[0].node

  let ssv_jahnShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "ssv_jahn"; })
  ssv_jahnShowcase = ssv_jahnShowcase[0] && ssv_jahnShowcase[0].node

  let kicker_tippspielShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "kicker_tippspiel"; })
  kicker_tippspielShowcase = kicker_tippspielShowcase[0] && kicker_tippspielShowcase[0].node

  let kicker_smart_tvShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "kicker_smart_tv"; })
  kicker_smart_tvShowcase = kicker_smart_tvShowcase[0] && kicker_smart_tvShowcase[0].node
  
  let kicker_vereinsheimShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "kicker_vereinsheim"; })
  kicker_vereinsheimShowcase = kicker_vereinsheimShowcase[0] && kicker_vereinsheimShowcase[0].node

  let aschendorff_appShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "aschendorff_app"; })
  aschendorff_appShowcase = aschendorff_appShowcase && aschendorff_appShowcase[0].node

  let dhb_handballShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "dhb_handball"; })
  dhb_handballShowcase = dhb_handballShowcase && dhb_handballShowcase[0].node

  let here_to_playShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "here_to_play"; })
  here_to_playShowcase = here_to_playShowcase[0] && here_to_playShowcase[0].node

  let ran_deShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "ran-de"; })
  ran_deShowcase = ran_deShowcase[0] && ran_deShowcase[0].node

  let flowspaceShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "flowspace"; })
  flowspaceShowcase = flowspaceShowcase[0] && flowspaceShowcase[0].node

  let dhb_online_academyShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "dhb_online_academy"; })
  dhb_online_academyShowcase = dhb_online_academyShowcase[0] && dhb_online_academyShowcase[0].node

  let nn_newsShowcase = data.prismic.allShowcases.edges.filter(showcase => { return showcase.node.key === "nn_news"; })
  nn_newsShowcase = nn_newsShowcase[0] && nn_newsShowcase[0].node




  function fluidWithCustomSizeGrid(fl) {
    fl.sizes = '(min-width: 800px) 50vw, 100vw'
    return fl;
  }

  const showCasesEven = () => {
    return data.prismic.allShowcases.edges.length % 2 === 0;
  }

  useEffect(() => {
    // const triggerElement = document.querySelector('.showcases')
    
    if(scrollMagic) {
      // new scrollMagic.instance.Scene({
      //   triggerElement: triggerElement,
      //   triggerHook: "onLeave",
      //   duration: (triggerElement.offsetHeight)
      // })
      // .setPin(document.querySelector('.showcases-badge'))
      // .addTo(scrollMagic.controller);
  
  
  
      // searches for elements with attribute 'data-activate'
      // when scrolled to this element, 'active' class will be added
      // to an element which has 'data-active-{value of data-activate}'
      const elements = document.querySelectorAll('.showcases [data-activate]');
      Array.prototype.forEach.call(elements, function(el, i){
        var target =  document.querySelectorAll('.showcases [data-activate-' + el.getAttribute('data-activate') + ']')
        new scrollMagic.instance.Scene({
          triggerElement: el
        })
        .setClassToggle(target, 'active')
        .addTo(scrollMagic.controller);
      });
  
  
      // const dataBgFixedElements = document.querySelectorAll('.showcases[data-bg-fixed]');
      // Array.prototype.forEach.call(dataBgFixedElements, function(el, i){
      const el = document.querySelector('.showcases[data-bg-fixed]');
      var target = el.querySelector('.showcases [data-bg-fixed-container]');
      var showcaseNav = document.querySelector('.header-nav-item-showcases');
      
      new scrollMagic.instance.Scene({
        triggerElement: el,
        triggerHook: "onLeave",
        duration: (el.offsetHeight - window.innerHeight)
      })
      .setPin(target)
      .addTo(scrollMagic.controller);
      
      new scrollMagic.instance.Scene({
        triggerElement: el,
        duration: el.offsetHeight
      })
      .setClassToggle(showcaseNav, 'active')
      .addTo(scrollMagic.controller);
      // });

    }
  }, [scrollMagic])

  function onShowAllShowcases(e) {
    e.preventDefault();
    setShowAllShowcases(true);
  }

  return (
    <section id="showcases" className="showcases-section">
      <div className="showcases-badge"><Badge /></div>
      <div className="showcases-section-container">

        <div className="showcases" data-bg-fixed>

        <div className="showcases-container">
          <div className="bg-fixed-container" data-bg-fixed-container>
              <div className="bg-fade bg-fade-cyan active" data-activate-showcase-cyan></div>
              <div className="bg-fade bg-fade-magenta" data-activate-showcase-magenta></div>
              <div className="bg-fade bg-fade-yellow" data-activate-showcase-yellow></div>
            </div>

          <div className="showcase showcase-kicker">
            <div className="showcase-content showcase-content-align-right">
              {kickerShowcase &&  <div>
                <h2 className="showcase-headline"><Link to={showcaseItemPath(kickerShowcase.showcaseitem._meta)}>{kickerShowcase.headline}</Link></h2>
                <div className="showcase-text">
                  {kickerShowcase.abstract && <RichText render={kickerShowcase.abstract} />}
                </div>
                {kickerShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(kickerShowcase.showcaseitem._meta)} className="link-hover-yellow">{t.showcases_read_more}</TikLink>
                }
              </div>}
            </div>

            <div className="showcase-media">
              <Link to={showcaseItemPath(kickerShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
                <Img 
                  fluid={data.showcaseKicker01.childImageSharp.fluid}
                  alt={kickerShowcase.image_alt}
                  className="showcase-media-img"
                />
              </Link>
            </div>
          </div>


          <div className="showcase showcase-sdtv" data-activate="showcase-magenta">
            <div className="showcase-content">
              {sdtvShowcase &&  <div>
                <h2 className="showcase-headline"><Link to={showcaseItemPath(sdtvShowcase.showcaseitem._meta)}>{sdtvShowcase.headline}</Link></h2>
                <div className="showcase-text">
                  {sdtvShowcase.abstract && <RichText render={sdtvShowcase.abstract} />}
                </div>
                {sdtvShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(sdtvShowcase.showcaseitem._meta)} className="link-hover-cyan">{t.showcases_read_more}</TikLink>
                }
              </div>}
            </div>

            <div className="showcase-media">
              <Link to={showcaseItemPath(sdtvShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
                <Img 
                  fluid={data.showcaseSdtv.childImageSharp.fluid}
                  alt={sdtvShowcase.image_alt}
                  className="showcase-media-img showcase-media-img-2"
                />
              </Link>
            </div>
          </div>

          <div className="showcase showcase-aschendorff" data-activate="showcase-yellow">
            <div className="showcase-content showcase-content-align-right">
              {aschendorff_appShowcase &&  <div>
                <h2 className="showcase-headline"><Link to={showcaseItemPath(aschendorff_appShowcase.showcaseitem._meta)}>{aschendorff_appShowcase.headline}</Link></h2>
                <div className="showcase-text">
                  {aschendorff_appShowcase.abstract && <RichText render={aschendorff_appShowcase.abstract} />}
                </div>
                {aschendorff_appShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(aschendorff_appShowcase.showcaseitem._meta)} className="link-hover-cyan">{t.showcases_read_more}</TikLink>
                }
              </div>}
            </div>

            <div className="showcase-media">
              <Link to={showcaseItemPath(aschendorff_appShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
                <Img 
                    fluid={data.showcaseaschendorff_App.childImageSharp.fluid}
                    alt={aschendorff_appShowcase.image_alt}
                    className="showcase-media-img"
                  />
              </Link>
            </div>
          </div>


          { !showAllShowcases && (
            <div className="show-all-showcases">
              <TikLink onClick={(e) => onShowAllShowcases(e)} className="link link-l link-hover-cyan">{t.showcases_show_all}</TikLink>
            </div>
          )}
        
        </div>
        
      </div>
      
        <div className={`showcase-grid ${showAllShowcases && 'active'}`}>
{/*
          <div className="showcase-grid-item showcase-grid-item-topfit bg-cyan">
            <div className="showcase-grid-content">
              {topfitShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(topfitShowcase.showcaseitem._meta)}>{topfitShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {topfitShowcase.abstract && <RichText render={topfitShowcase.abstract} />}
                </div>
                {topfitShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(topfitShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
            <Link to={showcaseItemPath(topfitShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img 
                fluid={fluidWithCustomSizeGrid(data.showcaseTopfit.childImageSharp.fluid)}
                alt={topfitShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>
*/}

          <div className="showcase-grid-item showcase-grid-item-dhb_handball bg-cyan">
            <div className="showcase-grid-content">
              {dhb_handballShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(dhb_handballShowcase.showcaseitem._meta)}>{dhb_handballShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {dhb_handballShowcase.abstract && <RichText render={dhb_handballShowcase.abstract} />}
              </div>
              {dhb_handballShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(dhb_handballShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(dhb_handballShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseDhb_handball.childImageSharp.fluid)}
                alt={dhb_handballShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div> 

          <div className="showcase-grid-item showcase-grid-item-ran bg-magenta">
            <div className="showcase-grid-content">
              {ran_deShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(ran_deShowcase.showcaseitem._meta)}>{ran_deShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {ran_deShowcase.abstract && <RichText render={ran_deShowcase.abstract} />}
              </div>
              {ran_deShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(ran_deShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(ran_deShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseRan_de.childImageSharp.fluid)}
                alt={ran_deShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-nordbayern bg-yellow">
            <div className="showcase-grid-content">
              {nordbayernShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(nordbayernShowcase.showcaseitem._meta)}>{nordbayernShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {nordbayernShowcase.abstract && <RichText render={nordbayernShowcase.abstract} />}
              </div>
              {nordbayernShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(nordbayernShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(nordbayernShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseNordbayern.childImageSharp.fluid)}
                alt={nordbayernShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>


          <div className="showcase-grid-item showcase-grid-item-heretoplay bg-cyan">
            <div className="showcase-grid-content">
              {here_to_playShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(here_to_playShowcase.showcaseitem._meta)}>{here_to_playShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {here_to_playShowcase.abstract && <RichText render={here_to_playShowcase.abstract} />}
              </div>
              {here_to_playShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(here_to_playShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(here_to_playShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseHere_to_play.childImageSharp.fluid)}
                alt={here_to_playShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-matchday bg-magenta">
            <div className="showcase-grid-content">
              {matchdayShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(matchdayShowcase.showcaseitem._meta)}>{matchdayShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {matchdayShowcase.abstract && <RichText render={matchdayShowcase.abstract} />}
              </div>
              {matchdayShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(matchdayShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(matchdayShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseMatchday01.childImageSharp.fluid)}
                alt={matchdayShowcase.image_alt}
		            className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-unserclub bg-yellow">
            <div className="showcase-grid-content">
              {unserclubShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(unserclubShowcase.showcaseitem._meta)}>{unserclubShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {unserclubShowcase.abstract && <RichText render={unserclubShowcase.abstract} />}
                </div>
                {unserclubShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(unserclubShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
                <Link to={showcaseItemPath(unserclubShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
                  <Img 
                    fluid={fluidWithCustomSizeGrid(data.showcaseUnserclub.childImageSharp.fluid)}
                    alt={unserclubShowcase.image_alt}
                    className="showcase-grid-img"
                    style={{position: "absolute"}}
                  />
                </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-nn_news bg-cyan">
            <div className="showcase-grid-content">
              {nn_newsShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(nn_newsShowcase.showcaseitem._meta)}>{nn_newsShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {nn_newsShowcase.abstract && <RichText render={nn_newsShowcase.abstract} />}
                </div>
                {nn_newsShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(nn_newsShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
            <Link to={showcaseItemPath(nn_newsShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img 
                fluid={fluidWithCustomSizeGrid(data.showcaseNn_news.childImageSharp.fluid)}
                alt={nn_newsShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-fein_raus bg-magenta">
            <div className="showcase-grid-content">
              {fein_rausShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(fein_rausShowcase.showcaseitem._meta)}>{fein_rausShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {fein_rausShowcase.abstract && <RichText render={fein_rausShowcase.abstract} />}
              </div>
              {fein_rausShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(fein_rausShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(fein_rausShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseFein_Raus.childImageSharp.fluid)}
                alt={fein_rausShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-redbull bg-yellow">
            <div className="showcase-grid-content">
              {redbullShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(redbullShowcase.showcaseitem._meta)}>{redbullShowcase.headline}</Link></h2>

              <div className="showcase-grid-text">
                {redbullShowcase.abstract && <RichText render={redbullShowcase.abstract} />}
              </div>
              {redbullShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(redbullShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            {/* <Link to={showcaseItemPath(sdtvShowcase.showcaseitem._meta)} title={t.showcases_read_more}> */}
            <Link to={showcaseItemPath(redbullShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseRedbull01.childImageSharp.fluid)}
                alt={redbullShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div> 

          <div className="showcase-grid-item showcase-grid-item-legionaere bg-cyan">
            <div className="showcase-grid-content">
              {legionaereShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(legionaereShowcase.showcaseitem._meta)}>{legionaereShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {legionaereShowcase.abstract && <RichText render={legionaereShowcase.abstract} />}
              </div>
              {legionaereShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(legionaereShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(legionaereShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseLegionaere.childImageSharp.fluid)}
                alt={legionaereShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-dhb_online_academy bg-magenta">
            <div className="showcase-grid-content">
              {dhb_online_academyShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(dhb_online_academyShowcase.showcaseitem._meta)}>{dhb_online_academyShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {dhb_online_academyShowcase.abstract && <RichText render={dhb_online_academyShowcase.abstract} />}
                </div>
                {dhb_online_academyShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(dhb_online_academyShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
            <Link to={showcaseItemPath(dhb_online_academyShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img 
                fluid={fluidWithCustomSizeGrid(data.showcaseDhb_online_academy.childImageSharp.fluid)}
                alt={dhb_online_academyShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-kicker_tippspiel bg-yellow">
            <div className="showcase-grid-content">
              {kicker_tippspielShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(kicker_tippspielShowcase.showcaseitem._meta)}>{kicker_tippspielShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {kicker_tippspielShowcase.abstract && <RichText render={kicker_tippspielShowcase.abstract} />}
              </div>
              {kicker_tippspielShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(kicker_tippspielShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(kicker_tippspielShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcasekicker_Tippspiel.childImageSharp.fluid)}
                alt={kicker_tippspielShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-flowspace bg-cyan">
            <div className="showcase-grid-content">
              {flowspaceShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(flowspaceShowcase.showcaseitem._meta)}>{flowspaceShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {flowspaceShowcase.abstract && <RichText render={flowspaceShowcase.abstract} />}
                </div>
                {flowspaceShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(flowspaceShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
            <Link to={showcaseItemPath(flowspaceShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img 
                fluid={fluidWithCustomSizeGrid(data.showcaseFlowspace.childImageSharp.fluid)}
                alt={flowspaceShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-kicker_vereinsheim bg-magenta">
            <div className="showcase-grid-content">
              {kicker_vereinsheimShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(kicker_vereinsheimShowcase.showcaseitem._meta)}>{kicker_vereinsheimShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {kicker_vereinsheimShowcase.abstract && <RichText render={kicker_vereinsheimShowcase.abstract} />}
              </div>
              {kicker_vereinsheimShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(kicker_vereinsheimShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(kicker_vereinsheimShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcasekicker_Vereinsheim.childImageSharp.fluid)}
                alt={kicker_vereinsheimShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-kicker_smart_tv bg-yellow">
            <div className="showcase-grid-content">
              {kicker_smart_tvShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(kicker_smart_tvShowcase.showcaseitem._meta)}>{kicker_smart_tvShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {kicker_smart_tvShowcase.abstract && <RichText render={kicker_smart_tvShowcase.abstract} />}
              </div>
              {kicker_smart_tvShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(kicker_smart_tvShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(kicker_smart_tvShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcasekicker_SmartTV.childImageSharp.fluid)}
                alt={kicker_smart_tvShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-ssv_jahn bg-cyan">
            <div className="showcase-grid-content">
              {ssv_jahnShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(ssv_jahnShowcase.showcaseitem._meta)}>{ssv_jahnShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {ssv_jahnShowcase.abstract && <RichText render={ssv_jahnShowcase.abstract} />}
              </div>
              {ssv_jahnShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(ssv_jahnShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(ssv_jahnShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcasessv_Jahn.childImageSharp.fluid)}
                alt={ssv_jahnShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          <div className="showcase-grid-item showcase-grid-item-verivox bg-magenta">
            <div className="showcase-grid-content">
              {verivoxShowcase && <>
                <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(verivoxShowcase.showcaseitem._meta)}>{verivoxShowcase.headline}</Link></h2>
                <div className="showcase-grid-text">
                  {verivoxShowcase.abstract && <RichText render={verivoxShowcase.abstract} />}
                </div>
                {verivoxShowcase.showcaseitem && 
                  <TikLink to={showcaseItemPath(verivoxShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
                }
              </>}
            </div>
            <Link to={showcaseItemPath(verivoxShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img 
                fluid={fluidWithCustomSizeGrid(data.showcaseVerivox.childImageSharp.fluid)}
                alt={verivoxShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div>

          {/*           
          <div className="showcase-grid-item showcase-grid-item-betsnap bg-cyan">
            <div className="showcase-grid-content">
              {betsnapShowcase && <>
              <h2 className="showcase-grid-headline"><Link to={showcaseItemPath(betsnapShowcase.showcaseitem._meta)}>{betsnapShowcase.headline}</Link></h2>
              <div className="showcase-grid-text">
                {betsnapShowcase.abstract && <RichText render={betsnapShowcase.abstract} />}
              </div>
              {betsnapShowcase.showcaseitem &&
              <TikLink to={showcaseItemPath(betsnapShowcase.showcaseitem._meta)}>{t.showcases_read_more}</TikLink>
              }
              </>}
            </div>
            <Link to={showcaseItemPath(betsnapShowcase.showcaseitem._meta)} title={t.showcases_read_more}>
              <Img
                fluid={fluidWithCustomSizeGrid(data.showcaseBetsnap.childImageSharp.fluid)}
                alt={betsnapShowcase.image_alt}
                className="showcase-grid-img"
                style={{position: "absolute"}}
              />
            </Link>
          </div> */}

          <div className={`showcase-grid-item showcase-grid-item-cta ${showCasesEven() ? '' : 'showcase-grid-item-full'}`}>
            <h2>{t.showcases_cta_headline}</h2>
            <TikLink href="mailto:directsupport@tickaroo.com" className="link-magenta gtm--made-by-showcases-contact-click">{t.showcases_cta_link}</TikLink>
          </div>
        </div>

      </div>
    </section>
  )
}


// export const query = graphql`
//   fragment SiteShowcases on Site {
//     siteMetadata {
//       author
//     }
//   }
// `

export const query = graphql`
  fragment showcaseMockupImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment showcaseGridMockupImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment ShowcasesFiles on Query {
    showcaseKicker01: file(relativePath: { eq: "showcases/kicker/kicker-ios-app-01.png" }) {
      ...showcaseMockupImage
    }
    showcaseRedbull01: file(relativePath: { eq: "showcases/redbull/red-bull.png" }) {
      ...showcaseMockupImage
    }
    showcaseMatchday01: file(relativePath: { eq: "showcases/matchday/matchday.png" }) {
      ...showcaseMockupImage
    }

    showcaseTopfit: file(relativePath: { eq: "showcases/topfit/topfit-02.png" }) {
      ...showcaseGridMockupImage
    }
    showcaseUnserclub: file(relativePath: { eq: "showcases/unserclub/unserclub-01.png" }) {
      ...showcaseGridMockupImage
    }
    showcaseFlowspace: file(relativePath: { eq: "showcases/flowspace/growspace-01.png" }) {
      ...showcaseGridMockupImage
    }
    showcaseSdtv: file(relativePath: { eq: "showcases/sdtv/sportdeutschland-tv.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseVerivox: file(relativePath: { eq: "showcases/verivox/verivox-02.png" }) {
      ...showcaseGridMockupImage
    }
    showcaseBetsnap: file(relativePath: { eq: "showcases/betsnap/madeby_mockup_betsnap_preview.png" }) {
      ...showcaseGridMockupImage
    }
    showcaseNordbayern: file(relativePath: { eq: "showcases/nordbayern/madeby_mockup_nordbayern_preview-2.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseLegionaere: file(relativePath: { eq: "showcases/legionaere/legionaere.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseFein_Raus: file(relativePath: { eq: "showcases/fein-raus/fein-raus-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcasessv_Jahn: file(relativePath: { eq: "showcases/ssv-jahn/ssv-jahn-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcasekicker_Tippspiel: file(relativePath: { eq: "showcases/kicker-tippspiel/kicker-tippspiel-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcasekicker_SmartTV: file(relativePath: { eq: "showcases/kicker-smart-tv/kicker-smart-tv-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcasekicker_Vereinsheim: file(relativePath: { eq: "showcases/kicker-vereinsheim/kicker-vereinsheim-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseaschendorff_App: file(relativePath: { eq: "showcases/aschendorff-app/aschendorff-app.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseDhb_handball: file(relativePath: { eq: "showcases/dhb-handball/dhb-handball-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseHere_to_play: file(relativePath: { eq: "showcases/here-to-play/heretoplay-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseRan_de: file(relativePath: { eq: "showcases/ran-de/ran-de-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseDhb_online_academy: file(relativePath: { eq: "showcases/dhb-online-academy/dhb-online-academy-01.png" }) {  
      ...showcaseGridMockupImage
    }
    showcaseNn_news: file(relativePath: { eq: "showcases/nn-news/nn-news-01.png" }) {  
      ...showcaseGridMockupImage
    }
    
  }
  fragment PrismicShowcases on PRISMIC {
    allShowcases(lang: $lang) {
      edges {
        node {
          headline
          abstract
          color
          image_alt
          showcaseitem {
            _linkType
            ... on PRISMIC_Showcaseitem {
              headline
              _meta {
                lang
                uid
                type
              }
            }
          }
          key
        }
      }
    }
  }
`



export default Showcase;