import React, {useState} from "react"
import {graphql} from "gatsby"
// import { RichText } from 'prismic-reactjs'
import { useIsIE } from "../components/helper"


import Layout from "../components/layout"
import Intro from "../components/index/intro"
import Showcases from "../components/index/showcases"
import Blogteaser from "../components/index/blogteaser"
import Services from "../components/index/services"
import Partners from "../components/index/partners"
import CTA from "../components/index/cta"

import SEO from "../components/seo"
// import {linkResolver} from "../utils/linkResolver";



const IndexPage = ({location, data, pageContext}) => {
    const lang = pageContext.langKey
    const texts = data.prismic.allHomepages.edges[0];

    
    const [scrollMagic, setScrollMagic] = useState();
    const isIE = useIsIE(); 


    React.useEffect(() => {
      // const isIE = /*@cc_on!@*/false || !!document.documentMode || true;
      if(isIE === false) {
        import("scrollmagic").then(ScrollMagic => {
          const controller = new ScrollMagic.Controller();
          setScrollMagic(() => ({
            instance: ScrollMagic, 
            controller: controller
          }));
        });
      }
    }, [isIE]);



    return (<Layout location={location} data={data} lang={lang}>
    {/* return (<Layout location={location} scrollMagic={scrollMagic}> */}
        <SEO 
          title={texts.node.title} 
          description={texts.node.metaDescription}/>

        <Intro data={data} lang={lang} scrollMagic={scrollMagic} />
        <Showcases data={data} scrollMagic={scrollMagic} />
        {/* <Showcases /> */}
        <Blogteaser data={data} lang={lang} scrollMagic={scrollMagic} />
        <Services data={data} lang={lang} scrollMagic={scrollMagic} />
        <Partners data={data} lang={lang} />
        <CTA data={data} scrollMagic={scrollMagic} />
        {/* <CTA scrollMagic={scrollMagic} /> */}
        {/* {RichText.render(texts.node.title)} */}
    </Layout>)
}


export const query = graphql`
fragment IndexFragment on Query {
  ...ShowcasesFiles
  prismic {
    ...fragmentPrismicLayout

    allPosts(first: 3, sortBy: date_DESC, lang: $lang) {
      edges {
        node {
          ...PostNode
        }
      }
    }
    allHomepages(lang: $lang) {
      edges {
        node {
          title
          metaDescription
          technologies {
            technology {
              _linkType
              ... on PRISMIC_Technology {
                name
                #logo
                #logoSharp {
                #  extension
                #}
                _meta {
                  uid
                }
              }
            }
          }
          intro_headline
          intro_slogan

          services_headline
          services_subheadline

          services_consulting_headline
          services_consulting_text
          services_consulting_link

          services_concept_headline
          services_concept_text

          services_design_headline
          services_design_text

          services_implementation_headline
          services_implementation_text

          services_technology_headline
          services_technology_text
          services_technology_list_start
          services_technology_list_end

          showcases_read_more
          showcases_show_all
          showcases_cta_headline
          showcases_cta_link

          blog_headline
          blog_subheadline
          blog_read_article
          blog_all_articles

          partners_headline
          partners_quote_author
          partners_quote_text

          products_headline
          products_subheadline
          
          letsgo_form_company_label
          letsgo_form_email_label
          letsgo_form_error
          letsgo_form_first_name_label
          letsgo_form_message_label
          letsgo_form_small_print
          letsgo_form_sending
          letsgo_form_last_name_label
          letsgo_form_subject_label
          letsgo_form_submit
          letsgo_form_success
          letsgo_headline
          letsgo_link_text
          letsgo_subheadline
          letsgo_text

          success_headline
          success_message
          success_back_button
        }
      }
    }
    ...PrismicShowcases
  }
}

query($lang: String = "de-de") {
  ...IndexFragment
}
`


export default IndexPage
