import React, { useEffect } from "react"
import { TikLink } from "../helper"

import ctaBadge from "../../images/made-by-logo.svg"

const CTA = ({ scrollMagic, data }) => {
  const t = data.prismic.allHomepages.edges[0].node

  useEffect(() => {
    // const triggerElement = document.querySelector('.cta')

    if (scrollMagic) {
      // new ScrollMagic.Scene({
      //   triggerElement: triggerElement,
      //   triggerHook: "onLeave",
      //   duration: (triggerElement.offsetHeight)
      // })
      // .setPin(document.querySelector('.showcases-badge'))
      // .addTo(scrollMagicController);

      // searches for elements with attribute 'data-activate'
      // when scrolled to this element, 'active' class will be added
      // to an element which has 'data-active-{value of data-activate}'
      // const elements = document.querySelectorAll('.cta [data-activate]');
      // Array.prototype.forEach.call(elements, function(el, i){
      //   var target =  document.querySelectorAll('.cta [data-activate-' + el.getAttribute('data-activate') + ']')
      //   new scrollMagic.instance.Scene({
      //     triggerElement: el
      //   })
      //   .setClassToggle(target, 'active')
      //   .addTo(scrollMagic.controller);
      // });

      // const dataBgFixedElements = document.querySelectorAll('.cta [data-bg-fixed]');
      // Array.prototype.forEach.call(dataBgFixedElements, function(el, i){
      //   var target = el.querySelector('.cta [data-bg-fixed-container]');

      //   new scrollMagic.instance.Scene({
      //     triggerElement: el,
      //     triggerHook: "onLeave",
      //     duration: (el.offsetHeight - window.innerHeight)
      //   })
      //   .setPin(target)
      //   .addTo(scrollMagic.controller);
      // });

      const sections = document.querySelectorAll(".cta [data-wipe]")
      Array.prototype.forEach.call(sections, function(el, i) {
        new scrollMagic.instance.Scene({
          triggerElement: el,
          triggerHook: "onLeave",
          duration: "100%",
        })
          .setPin(el, { pushFollowers: false })
          .addTo(scrollMagic.controller)
      })
    }
  }, [scrollMagic])

  return (
    <section id="cta">
      {/* <section id="cta" className="cta" data-bg-fixed> */}
      <div className="cta-badge">
        <img src={ctaBadge} alt="M" />
      </div>

      {/* <div className="bg-fixed-container" data-bg-fixed-container>
            <div className="bg-fade bg-fade-magenta active" data-activate-showcase-magenta></div>
            <div className="bg-fade bg-fade-yellow" data-activate-showcase-yellow></div>
          </div> */}

      <div className="bg-cyan bg-fixed" data-wipe>
        <div className="lets-go">
          <h1 className="cta gtm--made-by-cta">{t.letsgo_headline}</h1>
          <h3>{t.letsgo_link_text}</h3>
          <span dangerouslySetInnerHTML={{ __html: t.letsgo_text }} />

          <TikLink
            href={"mailto:oliver@tickaroo.com"}
            className="link-hover-yellow gtm--made-by-services-contact-click"
          >
            {t.services_consulting_link}
          </TikLink>
        </div>
      </div>

      {/* <div className="products bg-yellow" data-activate="showcase-yellow"> */}
      <div className="bg-yellow bg-fixed" data-wipe>
        <div className="products">
          <h1>{t.products_headline}</h1>
          <h2>{t.products_subheadline}</h2>

          <div className="product-list">
            <div className="product-list-item">
              <a
                href="https://www.tickaroo.com/about/de/live-blog.html"
                className="live-logo"
              >
                <span>Live</span>Blog
              </a>
            </div>

            <div className="product-list-item">
              <a
                href="https://www.tickaroo.com/about/de/push-notifications.html"
                className="live-logo"
              >
                <span>Live</span>Push
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
