import React, { useEffect } from "react"
import {graphql, Link} from "gatsby"
import {RichText } from 'prismic-reactjs'
import {blogPath, blogPostPath} from "../../utils/linkResolver";
import {blogDate} from "../../utils/blogDate";
import Img from "gatsby-image/withIEPolyfill";

import {TikLink} from "../helper";
import BlogBadge from "../../images/blog-badge.inline.svg";
import BlogPen from "../../images/blog-pen.inline.svg";


const Blogteaser = ({data, lang, scrollMagic}) => {
const t = data.prismic.allHomepages.edges[0].node;

  useEffect(() => {    
    if(scrollMagic) {
      const el = document.querySelector('#blog');
      var showcaseNav = document.querySelector('.header-nav-item-blog');
      new scrollMagic.instance.Scene({
        triggerElement: el,
        duration: el.offsetHeight
      })
      .setClassToggle(showcaseNav, 'active')
      .addTo(scrollMagic.controller);
    }
  }, [scrollMagic])

  function fluidWithCustomSize(fl) {
    fl.sizes = '(min-width: 500px) 33vw, 100vw'
    return fl;
  }

  return (
    <section id="blog" className="blogteaser">
      <div className="blog-badge"><BlogBadge /></div>

      <div className="blogheader">
        <h1 className="blogheader-headline">{t.blog_headline}</h1>
        <div className="blogheader-subline">{t.blog_subheadline}</div>
        <BlogPen className="blogheader-pen" />
      </div>

      
      <div className="teaser-list">
        {data.prismic.allPosts.edges.map((edge) => <div className="teaser-item" key={edge.node._meta.uid}>
          <Link to={blogPostPath(edge.node._meta)} className={"teaser-item-cover"}>
            {/* {edge.node.cover && <img  className="teaser-item-cover-img"
                  srcSet={`${edge.node.cover.square360.url} 360w, ${edge.node.cover.square540.url} 540w, ${edge.node.cover.square720.url} 720w`}
                  sizes="(min-width: 500px) 33vw, 100vw"
                  src={edge.node.cover.square540.url} alt={edge.node.cover.alt}
                  alt={edge.node.cover.alt}/>
            } */}
            { edge.node.imageSharp && <Img 
                className="teaser-item-cover-img"
                fluid={fluidWithCustomSize(edge.node.imageSharp.childImageSharp.fluid)}
                alt={edge.node.image && edge.node.image.alt} 
            />}
            <span className="teaser-item-date">{blogDate(edge.node.date)}</span>
          </Link>
          <div className="teaser-item-content">
            <Link to={blogPostPath(edge.node._meta)} className="teaser-item-headline">
              {RichText.render(edge.node.title)}
            </Link>
            <div className="teaser-item-abstract">
              {RichText.render(edge.node.abstract)}
            </div>
            <TikLink to={blogPostPath(edge.node._meta)} className="link-magenta">
              {t.blog_read_article}
            </TikLink>
          </div>
        </div>)}
      </div>

      <div className="teaser-all">
        <TikLink to={blogPath({lang: lang})} className="link-magenta link-l">{t.blog_all_articles}</TikLink>
      </div>
    </section>
  )
  
}

export const query = graphql`
  fragment PostNode on PRISMIC_Post {
    title
    date
    abstract
    image
    imageSharp(crop: "square") {
      childImageSharp {
        fluid(srcSetBreakpoints: [ 360, 640 ], maxWidth: 640, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    _meta {
      uid
      lang
      type
    }
  }
`


export default Blogteaser;