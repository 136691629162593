import { Date } from 'prismic-reactjs'

export const blogDate = (date, lang) => {
  return new Intl.DateTimeFormat(lang, {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Date(date))
}

export const blogDateYear = (date, lang) => {
  return new Intl.DateTimeFormat(lang, {year: 'numeric'}).format(Date(date))
}

export const blogDateWithoutYear = (date, lang) => {
  return new Intl.DateTimeFormat(lang, {month: '2-digit', day: '2-digit'}).format(Date(date))
}
