import React, { useEffect } from "react"

import { TikLink, TechList, TechListItem } from "../helper";

import ServicesBadge from "../../images/services-badge.inline.svg";
import ServicesBadgeEn from "../../images/services-badge.en.inline.svg";
import Megaphone from "../../images/services-megaphone.inline.svg";
import { rootCTAPath } from "../../utils/linkResolver";


const Services = ({data, lang, scrollMagic}) => {
  const t = data.prismic.allHomepages.edges[0].node;

  useEffect(() => {    
    if(scrollMagic) {
      const el = document.querySelector('#services');
      var showcaseNav = document.querySelector('.header-nav-item-services');
      new scrollMagic.instance.Scene({
        triggerElement: el,
        duration: el.offsetHeight
      })
      .setClassToggle(showcaseNav, 'active')
      .addTo(scrollMagic.controller);
    }
  }, [scrollMagic])

  if (!data) return null;

  const homepageData = data.prismic.allHomepages.edges[0].node;

  return (
    <section id="services" className="services">
      <div className="services-badge">{ lang.startsWith('en') ? <ServicesBadgeEn /> : <ServicesBadge />}</div>

      <div className="services-container">
        <h1 
          className="services-headline">
          <span dangerouslySetInnerHTML={{ __html: t.services_headline }} />
          <Megaphone className="services-headline-bg"/>
        </h1>
        <div className="services-subline">{ t.services_subheadline }</div>


        <div className="service-list">
          <div className="service">
            <h2 className="service-headline">{ t.services_consulting_headline }</h2>
            <div className="service-content">
              <span dangerouslySetInnerHTML={{ __html: t.services_consulting_text }} />
              <TikLink to={rootCTAPath({lang: lang})} className="link-cyan gtm--made-by-services-contact-click">{ t.services_consulting_link }</TikLink>
            </div>
          </div>
          <div className="service">
            <h2 className="service-headline">{ t.services_concept_headline }</h2>
            <div className="service-content">
              <span dangerouslySetInnerHTML={{ __html: t.services_concept_text }} />
            </div>
          </div>
          <div className="service">
            <h2 className="service-headline">{ t.services_design_headline }</h2>
            <div className="service-content">
            <span dangerouslySetInnerHTML={{ __html: t.services_design_text }} />
            </div>
          </div>
          <div className="service">
            <h2 className="service-headline">{ t.services_implementation_headline }</h2>
            <div className="service-content">
            <span dangerouslySetInnerHTML={{ __html: t.services_implementation_text }} />

              <h3 className="service-tec-headline">{ t.services_technology_headline }</h3>
              <span dangerouslySetInnerHTML={{ __html: t.services_technology_text }} />
              <p className="mb-0">{ t.services_technology_list_start }</p>
              <TechList className="service-tech-list">
                { homepageData.technologies.map((technology, technologyIndex) => 
                  <TechListItem technology={technology.technology} key={`service-tech-item-${technologyIndex}`} />
                )}
              </TechList>
              <div>
                { t.services_technology_list_end }
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default Services;